import saveAs from "file-saver";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MediumButton from "../../shared/Buttons/MediumButton";
import Footer from "../../shared/Footer";
import Navbar from "../../shared/NavBar";
import MenuItems from "./MenuItems";
import { ButtonGroup, MenuDesktopPageContainer } from "./MenuPageElements";
import { useTranslation } from "react-i18next";

const MenuPageDesktop = () => {
  const history = useNavigate();
  const { i18n } = useTranslation("menu");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const routeToWeekOffer = () => {
    history("/dania-dnia");
  };

  const downloadMenuAsPdf = () => {
    saveAs(
      `${process.env.PUBLIC_URL}/files/menu.pdf`,
      "restauracja-polonia-menu.pdf"
    );
  };

  const handleChangeLanguage = () => {
    if (i18n.language === "pl") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("pl");
    }
  };

  const getButtonTitle = () => {
    if (i18n.language === "pl") {
      return "Menu in English";
    } else {
      return "Zmień na polski";
    }
  };

  return (
    <React.Fragment>
      <MenuDesktopPageContainer>
        <Navbar />
        <ButtonGroup>
          <MediumButton
            primary
            title={"Zobacz ofertę tygodnia"}
            onClick={routeToWeekOffer}
          />

          <MediumButton
            title={getButtonTitle()}
            onClick={() => handleChangeLanguage()}
          />
        </ButtonGroup>
        <MenuItems />
        <MediumButton
          title={"Pobierz menu w PDF"}
          onClick={downloadMenuAsPdf}
        />
        <Footer />
      </MenuDesktopPageContainer>
    </React.Fragment>
  );
};

export default MenuPageDesktop;
