import React from "react";
import Zoom from "react-medium-image-zoom";
import {
  GalleryPicturesContainer,
  GalleryTitle,
  PictureItem,
} from "./GalleryMobilePageElements";
import { pictures } from "../pictures";


const MobileGalleryPictures = () => {
  return (
    <React.Fragment>
      <GalleryPicturesContainer>
        <GalleryTitle>Galeria zdjęć</GalleryTitle>
        {pictures.map((itemUrl, index) => (
          <Zoom key={index}>
            <PictureItem src={itemUrl.original} alt={itemUrl.alt} />
          </Zoom>
        ))}
      </GalleryPicturesContainer>
    </React.Fragment>
  );
};

export default MobileGalleryPictures;
