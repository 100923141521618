import React from "react";
import HomeDesktopPage from "./Desktop";
import HomeMobilePage from "./Mobile";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

interface IProps {
  isMobile: boolean;
}

const Home: React.FC<IProps> = ({ isMobile }) => {

  return (
    <React.Fragment>
      <Helmet>
        <title>Restauracja Polonia Rzeszów</title>
        <meta
          name="description"
          content="Tradycyjne potrawy kuchni polskiej w Rzeszowie. Odwiedź nas i odkryj niepowtarzalny smak naszych dań. Pyszne obiady w rozsądnych cenach. Restauracja Polonia jest położona niedaleko dworca w Rzeszowie."
        />
      </Helmet>
      {isMobile ? <HomeMobilePage /> : <HomeDesktopPage />}
    </React.Fragment>
  );
};

export default Home;
