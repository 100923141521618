import React, { useEffect } from "react";

const AddReview: React.FC = () => {
  useEffect(() => {
    window.location.replace("https://g.page/r/CVZ3VREB4ky0EB0/review");
    //eslint-disable-next-line
  }, []);

  return (
    <div style={{ textAlign: "center", margin: "20% auto 0% auto" }}>
      Przekierowuje na https://g.page/r/CVZ3VREB4ky0EB0/review
    </div>
  );
};

export default AddReview;
