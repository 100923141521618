import React from "react";
import { MenuItemsContainer } from "./MenuPageElements";
import MenuSingleItem from "./MenuSingleItem";
import menuPl from "../../../translations/pl/menuItems.json";
import menuEn from "../../../translations/en/menuItems.json";
import { useTranslation } from "react-i18next";

const MenuItems = () => {
  const { i18n } = useTranslation("menu");

  const getMenuByLanguage = () => {
    if (i18n.language === "pl") {
      return menuPl;
    } else {
      return menuEn;
    }
  };
  return (
    <MenuItemsContainer>
      {getMenuByLanguage().map((item) => (
        <MenuSingleItem
          category={item.category}
          items={item.items}
          key={item.category}
        />
      ))}
    </MenuItemsContainer>
  );
};

export default MenuItems;
