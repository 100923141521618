import saveAs from "file-saver";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MediumButton from "../../shared/Buttons/MediumButton";
import MobileFooter from "../../shared/MobileFooter";
import MobileNavbar from "../../shared/NavBar/Mobile";
import MenuMobileItems from "./MenuMobileItems";
import { ButtonGroupMobile, MenuMobilePageContainer } from "./MenuPageElements";
import { useTranslation } from "react-i18next";

const MenuPageMobile = () => {
  const history = useNavigate();
  const { i18n } = useTranslation("menu");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const routeToWeekOffer = () => {
    history("/dania-dnia");
  };

  const downloadMenuAsPdf = () => {
    saveAs(
      `${process.env.PUBLIC_URL}/files/menu.pdf`,
      "restauracja-polonia-menu.pdf"
    );
  };

  const handleChangeLanguage = () => {
    if (i18n.language === "pl") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("pl");
    }
  };

  const getButtonTitle = () => {
    if (i18n.language === "pl") {
      return "English Menu";
    } else {
      return "Zmień na polski";
    }
  };

  return (
    <MenuMobilePageContainer>
      <MobileNavbar />
      <ButtonGroupMobile>
        <MediumButton
          primary
          title={"Zobacz ofertę tygodnia"}
          onClick={routeToWeekOffer}
        />

        <MediumButton
          title={getButtonTitle()}
          onClick={() => handleChangeLanguage()}
        />
      </ButtonGroupMobile>
      <MenuMobileItems />
      <MediumButton title={"Pobierz menu w PDF"} onClick={downloadMenuAsPdf} />
      <MobileFooter />
    </MenuMobilePageContainer>
  );
};

export default MenuPageMobile;
