import React from "react";
import { Oval } from "react-loader-spinner";
import {
  DARK_BACKGROUND_COLOR,
  POLONIA_YELLOW_COLOR,
} from "../../utils/colors";

const Loader: React.FC = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: `${DARK_BACKGROUND_COLOR}`,
        zIndex: 1001,
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        justifyContent: "center",
      }}
    >
      <Oval
        height={60}
        width={60}
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        color={POLONIA_YELLOW_COLOR}
        strokeWidth={5}
        strokeWidthSecondary={5}
      />
    </div>
  );
};

export default Loader;
