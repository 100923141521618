import React from "react";
import GalleryPageDesktop from "./Desktop/GalleryPageDesktop";
import GalleryMobilePage from "./Mobile";
import { Helmet } from "react-helmet";

interface IProps {
  isMobile: boolean;
}

const Gallery: React.FC<IProps> = ({ isMobile }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Restauracja Polonia Galeria</title>
        <meta
          name="description"
          content="Restauracja Polonia galeria. Restauracja Polonia zdjęcia. Restauracja Polonia zdjęcia wewnątrz. Resturacja Polonia komunia. Restauracja Polonia Rzeszów. Gdzie zorganizować komunię. Sala do wynajęcia Rzeszów. Restauracja Rzeszów."
        />
      </Helmet>
      {isMobile ? <GalleryMobilePage /> : <GalleryPageDesktop />}
    </React.Fragment>
  );
};

export default Gallery;
