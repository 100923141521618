import React, { useEffect } from "react";

interface IProps {
  isMobile: boolean;
}

const OrderOnline: React.FC<IProps> = ({ isMobile }) => {
  useEffect(() => {
    window.location.replace(
      "https://glovoapp.com/pl/pl/rzeszow/restauracja-polonia-rzeszow/"
    );
    //eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <div style={{ textAlign: "center", margin: "20% auto 0% auto" }}>
        PRZEKIEROWUJE NA GLOVO
      </div>
      {/* ZAMÓWIENIA RESTAUMATIC - NIEWSPIERANE */}
      {/* {isMobile ? <OrderOnlinePageMobile /> : <OrderOnlinePageDesktop />} */}
    </React.Fragment>
  );
};

export default OrderOnline;
