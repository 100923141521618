import React from "react";
import ContactDesktopPage from "./Desktop/ContactDesktopPage";
import ContactMobilePage from "./Mobile";
import { Helmet } from "react-helmet";

interface IProps {
  isMobile: boolean;
}

const Contact: React.FC<IProps> = ({ isMobile }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Restauracja Polonia Kontakt</title>
        <meta
          name="description"
          content="Restauracja Polonia kontakt. Restauracja Polonia jak się skontakować. Restauracja Rzeszów."
        />
      </Helmet>
      {isMobile ? <ContactMobilePage /> : <ContactDesktopPage />}
    </React.Fragment>
  );
};

export default Contact;
