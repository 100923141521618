import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const LanguageDetector = () => {
  const [searchParams] = useSearchParams();
  const { i18n } = useTranslation();

  useEffect(() => {
    const langParam = searchParams.get("lang");
    if (langParam === "en") {
      i18n.changeLanguage("en");
    }
    //eslint-disable-next-line
  }, [searchParams]);

  return <React.Fragment />;
};

export default LanguageDetector;
