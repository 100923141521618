import picture1 from "../../images/gallery/picture1.jpg";
import picture2 from "../../images/gallery/picture2.jpg";
import picture3 from "../../images/gallery/picture3.jpg";
import picture4 from "../../images/gallery/picture4.jpg";
import picture5 from "../../images/gallery/picture5.jpg";
import picture6 from "../../images/gallery/picture6.jpg";
import picture7 from "../../images/gallery/picture7.jpg";
import picture8 from "../../images/gallery/picture8.jpg";
import picture9 from "../../images/gallery/picture9.jpg";
import picture10 from "../../images/gallery/picture10.jpg";
import picture11 from "../../images/gallery/picture11.jpg";
import picture12 from "../../images/gallery/picture12.jpg";
import picture13 from "../../images/gallery/picture13.jpg";
import picture14 from "../../images/gallery/picture14.jpg";
import picture15 from "../../images/gallery/picture15.jpg";
import picture16 from "../../images/gallery/picture16.jpg";
import picture17 from "../../images/gallery/picture17.jpg";
import picture18 from "../../images/gallery/picture18.jpg";

export const pictures = [
  { original: picture1, alt: "Restauracja Polonia- Pyszne placki po węgiersku" },
  { original: picture2, alt: "Restauracja Polonia- Smaczne desery" },
  { original: picture3, alt: "Restauracja Polonia- Pyszny domowy obiad" },
  { original: picture4, alt: "Pyszny rosół" },
  { original: picture5, alt: "Pyszny obiad" },
  { original: picture6, alt: "Pyszny obiad" },
  { original: picture7, alt: "Restauracja Polonia- zdjęcie z zewnątrz" },
  { original: picture8, alt: "Restauracja Polonia- zdjęcie z zewnątrz" },
  { original: picture9, alt: "Restauracja Polonia- zdjecie sali" },
  { original: picture10, alt: "Restauracja Polonia- zdjecie sali" },
  { original: picture11, alt: "Restauracja Polonia- zdjecie sali" },
  { original: picture12, alt: "Restauracja Polonia- zdjecie sali" },
  { original: picture13, alt: "Restauracja Polonia- zdjecie sali" },
  { original: picture14, alt: "Wystrój sali na imprezę" },
  { original: picture15, alt: "Wystrój sali na imprezę" },
  { original: picture16, alt: "Wystrój sali na imprezę" },
  { original: picture17, alt: "Wystrój sali na imprezę" },
  { original: picture18, alt: "Wystrój sali na imprezę" },
];
