import React from "react";
import WeekOfferPageDesktop from "./WeekOfferPageDesktop";
import WeekOfferPageMobile from "./WeekOfferPageMobile";
import { Helmet } from "react-helmet";

interface IProps {
  isMobile: boolean;
}

const WeekOffer: React.FC<IProps> = ({ isMobile }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Restauracja Polonia Dania Dnia</title>
        <meta
          name="description"
          content="Restauracja Polonia dania dnia. Jedzenie w restauracji Polonia. Restauracja Polonia Rzeszów dania dnia. Pyszne domowe jedzenie w Rzeszowie. Restauracja w Rzeszowie oferta."
        />
      </Helmet>
      {isMobile ? <WeekOfferPageMobile /> : <WeekOfferPageDesktop />}
    </React.Fragment>
  );
};

export default WeekOffer;
