import React from "react";
import { Helmet } from "react-helmet";
import MenuPageDesktop from "./Desktop/MenuPageDesktop";
import MenuPageMobile from "./Mobile";

interface IProps {
  isMobile: boolean;
}

const Menu: React.FC<IProps> = ({ isMobile }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Restauracja Polonia Menu</title>
        <meta
          name="description"
          content="Restauracja Polonia Menu. Jedzenie w restauracji Polonia. Restauracja Polonia Rzeszów Menu. Pyszne domowe jedzenie w Rzeszowie. Restauracja w Rzeszowie Menu."
        />
      </Helmet>
      {isMobile ? <MenuPageMobile /> : <MenuPageDesktop />}
    </React.Fragment>
  );
};

export default Menu;
