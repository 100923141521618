import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import menu_pl from "./translations/pl/menuItems.json";
import menu_en from "./translations/en/menuItems.json";
import LanguageDetector from "i18next-browser-languagedetector";



i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false },
  lng: "pl",
  fallbackLng: "pl",
  resources: {
    pl: {
      menu: menu_pl,
    },
    en: {
      menu: menu_en,
    },
  },
  detection: {
    order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
    lookupQuerystring: "lng",
    lookupCookie: "i18n",
    lookupLocalStorage: "i18nextLng",
    caches: ["localStorage", "cookie"],
  },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
